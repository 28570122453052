define("ember-svg-jar/helpers/svg-jar", ["exports", "@ember/component/helper", "ember-svg-jar/utils/make-svg"], function (_exports, _helper, _makeSvg) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.svgJar = svgJar;
  function getInlineAsset(assetId) {
    let result = null;
    try {
      result = require(`ember-svg-jar/inlined/${assetId}`).default;
    } catch (err) {
      // skip
    }
    try {
      result = {}[assetId].default;
    } catch (err) {
      // skip
    }
    return result;
  }
  function svgJar(assetId, svgAttrs) {
    return (0, _makeSvg.default)(assetId, svgAttrs, getInlineAsset);
  }
  var _default = _exports.default = (0, _helper.helper)(function svgJarHelper(_ref, svgAttrs) {
    let [assetId] = _ref;
    return svgJar(assetId, svgAttrs);
  });
});