define("ember-validators/index", ["exports", "@ember/debug", "@ember/utils", "@embroider/macros/es-compat2", "ember-validators/collection", "ember-validators/confirmation", "ember-validators/date", "ember-validators/ds-error", "ember-validators/exclusion", "ember-validators/format", "ember-validators/inclusion", "ember-validators/length", "ember-validators/messages", "ember-validators/number", "ember-validators/presence"], function (_exports, _debug, _utils, _esCompat, _importSync20, _importSync40, _importSync60, _importSync80, _importSync100, _importSync120, _importSync140, _importSync160, _importSync180, _importSync210, _importSync230) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validate = validate;
  function validate(type) {
    let validator;
    if (type === 'collection') {
      validator = import_collection();
    } else if (type === 'confirmation') {
      validator = import_confirmation();
    } else if (type === 'date') {
      validator = import_date();
    } else if (type === 'ds-error') {
      validator = import_ds_error();
    } else if (type === 'exclusion') {
      validator = import_exclusion();
    } else if (type === 'format') {
      validator = import_format();
    } else if (type === 'inclusion') {
      validator = import_inclusion();
    } else if (type === 'length') {
      validator = import_length();
    } else if (type === 'messages') {
      validator = import_messages();
    } else if (type === 'number') {
      validator = import_number();
    } else if (type === 'presence') {
      validator = import_presence();
    }
    (true && !((0, _utils.isPresent)(validator)) && (0, _debug.assert)(`Validator not found of type: ${type}.`, (0, _utils.isPresent)(validator)));
    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }
    return validator.default(...args);
  }
  function import_collection() {
    return (0, _esCompat.default)(_importSync20);
  }
  function import_confirmation() {
    return (0, _esCompat.default)(_importSync40);
  }
  function import_date() {
    return (0, _esCompat.default)(_importSync60);
  }
  function import_ds_error() {
    return (0, _esCompat.default)(_importSync80);
  }
  function import_exclusion() {
    return (0, _esCompat.default)(_importSync100);
  }
  function import_format() {
    return (0, _esCompat.default)(_importSync120);
  }
  function import_inclusion() {
    return (0, _esCompat.default)(_importSync140);
  }
  function import_length() {
    return (0, _esCompat.default)(_importSync160);
  }
  function import_messages() {
    return (0, _esCompat.default)(_importSync180);
  }
  function import_number() {
    return (0, _esCompat.default)(_importSync210);
  }
  function import_presence() {
    return (0, _esCompat.default)(_importSync230);
  }
});